<template>
    <!-- 返回的page接口 默认为useType == 3  -->
    <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
        <template #header>
            <view-search-form :filterKeys="['userType']" :queryInfo.sync="queryInfo" @on-search="renderTable(1)"
                keyword-placeholder="姓名" :tool-list="['keyword', 'department']"></view-search-form>
            <div class="header-button mb-1">
                <el-tag type="successNum">教师用水标准：{{ sandard }}L</el-tag>
                <el-button @click="showSandardDialog('teacher')" type="primary" size="small">更改教师用水标准</el-button>
            </div>
        </template>
        <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
            <el-table-column prop="realName" label="姓名"></el-table-column>
            <el-table-column prop="deptName" label="部门"></el-table-column>
            <el-table-column prop="dormitoryName" label="所在宿舍">
                <template v-slot="{ row }">
                    <span v-if="row.dormitoryName && row.dormitoryBedNo">{{ row.dormitoryName }}{{ row.roomName }}室{{
                        row.dormitoryBedNo
                    }}号床</span>
                </template>
            </el-table-column>
            <el-table-column prop="waterUseLimit" label="当前用水量"></el-table-column>
            <el-table-column label="操作" max-width="150" fixed="right">
                <template v-slot="{ row }">
                    <el-button type="text" @click="showDialog(row)">
                        更改用水量
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <dialog-refresh-water :waterInfo="checkedData" ref="dialogRef" :visible.sync='visibleDialog' v-if='visibleDialog'
            @on-close='renderTable' />
        <dialog-refresh-sandard :formData="sandardData" ref="dialogSandardRef" :visible.sync='visibleSandardDialog'
            v-if='visibleSandardDialog' @on-close='renderTable' />
    </table-view>
</template>

<script>
import { pageTeaWaterUseSet } from '@/api/finance-manage/waterUseSet-api.js'
import tableView from '@/vue/mixins/table-view'
import dialogRefreshWater from './components/dialogRefreshWater.vue'
import dialogRefreshSandard from './components/dialogRefreshSandard.vue'
export default {
    mixins: [tableView],
    components: { dialogRefreshWater, dialogRefreshSandard },

    data() {
        return {
            queryInfo: {
                deptId: null,

            },
            formData: {
                id: '',
                realName: ''
            },
            checkedData: null,
            sandard: null,
            sandardId: null,
            sandardData: null,
            visibleSandardDialog: false
        }
    },
    mounted() {
        this.renderTable()
    },
    methods: {
        async renderTable(pageNum) {
            await this.getTableData(pageTeaWaterUseSet, pageNum)
            this.getSandard()
        },
        async getSandard() {
            // dayWaterUseLiter 学生用水
            // teacherDayWaterUseLiter 教师用水
            const res = await this.$http.getSysParamsByKey('teacherDayWaterUseLiter')
            this.sandard = res.data.paramValue
            this.sandardId = res.data.id

        },
        // 改变用水量
        showDialog(data) {
            this.checkedData = {
                userId: data.id,
                realName: data.realName,
                dormitoryName: data.dormitoryName,
                waterUseLimit: data.waterUseLimit,

            }
            this.visibleDialog = true
        },
        // 更改用水标准
        showSandardDialog(flag) {
            this.sandardData = {
                flag,
                id: this.sandardId,
                sandard: this.sandard,
            }
            this.visibleSandardDialog = true

        }
    },

}
</script>

<style></style>